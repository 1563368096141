import revive_payload_client_Q70AQpYZrv from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XGo65Qn74z from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gauy1zCGo6 from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wKPYhqkJvM from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.19.0_vite@5.3.5_vue@3.4.34/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_K8cfhJuSZ3 from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TN84MvdZQa from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Bwb5ITpJpt from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZqvUgz6Ysq from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_36EYAKEDKl from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.19.0_typescript@5.5.4_vue@3.4.34/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/ecomadm/app/releases/175/.nuxt/components.plugin.mjs";
import prefetch_client_vWcCOqqk1T from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.19.0_typescript@5.5.4_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_8BTGlQVP8v from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_rollup@4.19.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_IFTXugHVhN from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.19.0_vite@5.3.5_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_igfp1Rfrnp from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.19.0_vite@5.3.5_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_AbDn3PSNqQ from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.19.0_vue@3.4.34/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_BY7P5HC6OD from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/plugin.mjs";
import plugin_WJP4IXCWmD from "/home/ecomadm/app/releases/175/node_modules/.pnpm/nuxt-graphql-client@0.2.36_graphql-tag@2.12.6/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_uyyVFv22jh from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.19.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import rawlplug_ui_6DoqzJ8zlH from "/home/ecomadm/app/releases/175/modules/rawlplug-ui/plugins/rawlplug-ui.ts";
import virtual_scroller_j0vDvJKkJH from "/home/ecomadm/app/releases/175/modules/rawlplug-ui/plugins/virtual-scroller.ts";
import acl_t5sS6lHB6p from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/plugins/acl.ts";
import authRedirect_loUuHfcIX1 from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/plugins/authRedirect.ts";
import sectionData_vsvWj0CJ7c from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/plugins/sectionData.ts";
import setHeaders_3PDyKigfaa from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/plugins/setHeaders.ts";
import watchCustomerSession_client_OINYrv7Nny from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/plugins/watchCustomerSession.client.ts";
import customerCreditHold_uaOaR07KmZ from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/customerCreditHold.ts";
import magentoRestApi_Rxph9rYfJy from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/magentoRestApi.ts";
import onGqlErrors_cdhJBIAsKr from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/onGqlErrors.ts";
import parseGqlError_80UVNGDPbe from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/parseGqlError.ts";
import setHeadTags_TuBvNAMaiT from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/setHeadTags.ts";
import veeValidateEcommerce_2k9th3UBvH from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/plugins/veeValidateEcommerce.ts";
import nuxt_typo3_hooks_YEMuImgsK0 from "/home/ecomadm/app/releases/175/modules/rawlplug-cms/plugins/nuxt-typo3-hooks.ts";
import chunkError_client_orW7FAK9A7 from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/chunkError.client.ts";
import fixIosInputZoom_sxKROb73VY from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/fixIosInputZoom.ts";
import maintenanceMode_client_of4DxOt2sw from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/maintenanceMode.client.ts";
import mediaData_UTgroMKJHM from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/mediaData.ts";
import piwik_JAi7Zj0BOi from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/piwik.ts";
import sentry_client_7cIuQv2GUF from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/sentry.client.ts";
import veeValidate_rMbef5NodW from "/home/ecomadm/app/releases/175/modules/rawlplug-base/plugins/veeValidate.ts";
export default [
  revive_payload_client_Q70AQpYZrv,
  unhead_XGo65Qn74z,
  router_gauy1zCGo6,
  _0_siteConfig_wKPYhqkJvM,
  payload_client_K8cfhJuSZ3,
  navigation_repaint_client_TN84MvdZQa,
  check_outdated_build_client_Bwb5ITpJpt,
  chunk_reload_client_ZqvUgz6Ysq,
  plugin_vue3_36EYAKEDKl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vWcCOqqk1T,
  nuxt_plugin_8BTGlQVP8v,
  pwa_icons_IFTXugHVhN,
  pwa_client_igfp1Rfrnp,
  i18n_AbDn3PSNqQ,
  plugin_BY7P5HC6OD,
  plugin_WJP4IXCWmD,
  plugin_uyyVFv22jh,
  rawlplug_ui_6DoqzJ8zlH,
  virtual_scroller_j0vDvJKkJH,
  acl_t5sS6lHB6p,
  authRedirect_loUuHfcIX1,
  sectionData_vsvWj0CJ7c,
  setHeaders_3PDyKigfaa,
  watchCustomerSession_client_OINYrv7Nny,
  customerCreditHold_uaOaR07KmZ,
  magentoRestApi_Rxph9rYfJy,
  onGqlErrors_cdhJBIAsKr,
  parseGqlError_80UVNGDPbe,
  setHeadTags_TuBvNAMaiT,
  veeValidateEcommerce_2k9th3UBvH,
  nuxt_typo3_hooks_YEMuImgsK0,
  chunkError_client_orW7FAK9A7,
  fixIosInputZoom_sxKROb73VY,
  maintenanceMode_client_of4DxOt2sw,
  mediaData_UTgroMKJHM,
  piwik_JAi7Zj0BOi,
  sentry_client_7cIuQv2GUF,
  veeValidate_rMbef5NodW
]