import { default as _91_46_46_46slug_93l1haPQm9RtMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexM6aL4AvaRNMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesIAmtpCy8BMMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companywW95jkZ2j0Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contact0qdkDk3znTMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexACiuZwmnkMMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as liststjCA1iE0adMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93io4CyCWREOMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersCoXmD2ALOKMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingu0pHiKYpv9Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45usercZE7rNp00DMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editfHMA9vI7jtMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexmnQIWQr1jTMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newG1pFV6u1iuMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editFPUU424qrfMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexZt715lCfWEMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profilexixnHpN5PpMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountQ764wdHIahMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93ogumm5xHj7Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryxOtBBEET67Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartE9ZSn8R7jIMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentFTJ85Rx6ysMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summary4PVWioYjJIMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutk1xeEC79YSMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordCkqgh8J5EtMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordvDgYERvTLHMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indextfATq8EZ4hMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexITElwIIYlyMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productIK2gtfY4ZTMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93HiuUZwHKTHMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as index2eiqo4CzlkMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerbEaTF5SqxPMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveLXSHupuaAdMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmseM3Jbj2v60Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storetIlwnRdT4iMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchusoXxG6oKHMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionPWeoHUVleKMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSection5rTRhlyBxGMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexYvTe43QjVtMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClient3qn4YtEeUJMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElementKFJe4xIhDlMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementPaA5ACP4MBMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionWhsPSxz3X4Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeader9eBidBdUu0Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionAbVQFwymaLMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugvoWrtAxHCIMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormMRWBxQySK0Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModaltPLeblXHW8Meta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugSticky0IaLQkrswDMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtamz2pg7DRzoMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftWGZ6BRlUYxMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightnSCNqUsubsMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPagination7tp9xUzRXRMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionZJoPESSQoWMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesSUWKmaTSfYMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSection21MlUaiOnDMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationsJ0B3I48SIMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightT8wPIEhVbQMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexxLQQYdzL3eMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constants32ri90hfsAMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPagea5lPpvvaKDMeta } from "/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PagehYgktdEJ4nMeta } from "/home/ecomadm/app/releases/175/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93l1haPQm9RtMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-cms/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountQ764wdHIahMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account.vue").then(m => m.default || m),
    children: [
  {
    name: addressesIAmtpCy8BMMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesIAmtpCy8BMMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/addresses.vue").then(m => m.default || m)
  },
  {
    name: companywW95jkZ2j0Meta?.name ?? "locale-account-company",
    path: "company",
    meta: companywW95jkZ2j0Meta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/company.vue").then(m => m.default || m)
  },
  {
    name: contact0qdkDk3znTMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contact0qdkDk3znTMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/contact.vue").then(m => m.default || m)
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/index.vue").then(m => m.default || m)
  },
  {
    name: liststjCA1iE0adMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: liststjCA1iE0adMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/lists.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue").then(m => m.default || m)
  },
  {
    name: ordersCoXmD2ALOKMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersCoXmD2ALOKMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payments_45and_45billingu0pHiKYpv9Meta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingu0pHiKYpv9Meta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45usercZE7rNp00DMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editfHMA9vI7jtMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexmnQIWQr1jTMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newG1pFV6u1iuMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue").then(m => m.default || m)
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editFPUU424qrfMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexZt715lCfWEMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexZt715lCfWEMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue").then(m => m.default || m)
  },
  {
    name: profilexixnHpN5PpMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profilexixnHpN5PpMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/account/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/category.vue").then(m => m.default || m),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93ogumm5xHj7Meta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkoutk1xeEC79YSMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutk1xeEC79YSMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: cartE9ZSn8R7jIMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartE9ZSn8R7jIMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentFTJ85Rx6ysMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summary4PVWioYjJIMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordCkqgh8J5EtMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordvDgYERvTLHMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/event/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/newsletter/index.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productIK2gtfY4ZTMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/product.vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93HiuUZwHKTHMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue").then(m => m.default || m)
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: index2eiqo4CzlkMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue").then(m => m.default || m)
  },
  {
    name: registerbEaTF5SqxPMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerbEaTF5SqxPMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-customer/pages/[locale]/register.vue").then(m => m.default || m)
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveLXSHupuaAdMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/resolve.vue").then(m => m.default || m)
  },
  {
    name: searchusoXxG6oKHMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchusoXxG6oKHMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search.vue").then(m => m.default || m),
    children: [
  {
    name: cmseM3Jbj2v60Meta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmseM3Jbj2v60Meta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search/cms.vue").then(m => m.default || m)
  },
  {
    name: storetIlwnRdT4iMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storetIlwnRdT4iMeta || {},
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-ecom/pages/[locale]/search/store.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts").then(m => m.default || m)
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js").then(m => m.default || m)
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/175/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/175/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.4.34/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue").then(m => m.default || m)
  }
]